// extracted by mini-css-extract-plugin
export var topLine = "about-module--topLine--1xRsC";
export var bottomLine = "about-module--bottomLine--AjQ1Y";
export var aboutContainer = "about-module--aboutContainer--Vh1+I";
export var carouselImg = "about-module--carouselImg--oE6eJ";
export var carouselDesc = "about-module--carouselDesc--3Q4hQ";
export var aboutTab = "about-module--aboutTab--+98vK";
export var aboutTabTitle = "about-module--aboutTabTitle--cLn7D";
export var restoreRow = "about-module--restoreRow--2fLis";
export var restoreCol = "about-module--restoreCol--lurHa";
export var restoreIcon = "about-module--restoreIcon--AT6YX";
export var restoreImg = "about-module--restoreImg--NiZs+";
export var restoreTitle = "about-module--restoreTitle--VpLXX";
export var restoreDesc = "about-module--restoreDesc--iDg6U";
export var futureTitle = "about-module--futureTitle--7qDNW";
export var futureDesc = "about-module--futureDesc--CC0-I";
export var aboutQuote = "about-module--aboutQuote--KwyAI";
export var aboutQuoteContent = "about-module--aboutQuoteContent--Je5Dg";
export var aboutQuoteDesc = "about-module--aboutQuoteDesc--rSyHU";
export var feedbackForm = "about-module--feedbackForm--6H2Yz";